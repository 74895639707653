<template>
  <div class="login bg-bgsection d-flex flex-column">
    <LoginHeader>
      <p
        v-if="$device.isMobile"
        class="m-0 mr-3 text-secondary font-weight-bold"
        @click="onClickSignUp"
      >
        {{ $t('免費註冊') }}
      </p>
      <div v-else class="web-btn-wrapper">
        <b-button variant="white" size="lg" @click.stop="onClickSignUp">
          {{ $t('免費註冊') }}
        </b-button>
      </div>
    </LoginHeader>

    <div class="login-wrapper scroll-bar">
      <div
        class="login-container d-flex align-items-center justify-content-center flex-column mx-auto px-3 px-md-0"
      >
        <h3 class="mb-4 mb-md-5">{{ $t('登入') }}</h3>
        <div class="sub-title mb-3 text-center" style="width: 280px">
          {{ $t('快速註冊 / 登入方式') }}
        </div>
        <div class="oauth-login-wrapper d-flex justify-content-between w-100">
          <GoogleLoginButton v-if="loginMethods.includes('google')">
            <div class="oauth-login-btn">
              <img :src="GoogleIcon" />
            </div>
          </GoogleLoginButton>
          <AppleLoginButton v-if="loginMethods.includes('apple')" class="mx-3">
            <div class="oauth-login-btn">
              <img :src="AppleIcon" />
            </div>
          </AppleLoginButton>
          <LineLoginButton v-if="loginMethods.includes('line')">
            <div class="oauth-login-btn">
              <img :src="LineIcon" />
            </div>
          </LineLoginButton>
        </div>

        <hr class="divider w-100 my-4" style="height: 1px" />

        <div class="sub-title mb-3 text-center" style="width: 244px">
          {{ $t('訪客帳號登入') }}
        </div>

        <b-form
          v-if="loginMethods.includes('username')"
          class="w-100"
          @submit.prevent="requestLogin()"
        >
          <b-form-group
            label-for="input-1"
            :label="$t('訪客 ID')"
            class="account-input mb-4"
          >
            <b-form-input
              id="username"
              ref="username"
              v-model="$v.formData.username.$model"
              name="username"
              aria-describedby="input-username-feedback"
              type="text"
              :placeholder="$t('訪客 ID')"
              :state="formData.isUsernameValid"
              @input="usernameInputHandler"
            ></b-form-input>
            <b-form-invalid-feedback
              id="input-username-feedback"
              :state="formData.isUsernameValid"
              class="mt-0"
            >
              <div v-if="!$v.formData.username.required" class="error">
                {{ $t('請輸入ID') }}
              </div>
              <div v-else-if="backendError === 'User not exist!'" class="error">
                {{ $t('ID尚未註冊') }}
              </div>
              <div v-else-if="backendError === '帳號被禁止'" class="error">
                {{ $t('您的帳號已被停用') }}
              </div>
              <div v-else-if="$v.formData.username.$invalid" class="error">
                {{ $t('請輸入正確的ID') }}
              </div>
              <div v-else class="error">
                {{ $t('伺服器發生錯誤，請稍後再試') }}
              </div>
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group label-for="password" :label="$t('密碼')">
            <b-form-input
              id="password"
              ref="password"
              v-model="$v.formData.password.$model"
              name="password"
              aria-describedby="input-password-feedback"
              type="password"
              maxlength="15"
              :placeholder="$t('設定 8 位以上英數密碼')"
              :state="formData.isPasswordValid"
              @input="passwordInputHandler"
            ></b-form-input>
            <b-form-invalid-feedback
              id="input-password-feedback"
              :state="formData.isPasswordValid"
              class="mt-0"
            >
              <div v-if="!$v.formData.password.required" class="error">
                {{ $t('請輸入密碼') }}
              </div>
              <div v-else-if="!$v.formData.password.minLength" class="error">
                {{ $t('不能少於8碼') }}
              </div>
              <div v-else-if="backendError === 'Wrong password!'" class="error">
                {{ $t('密碼錯誤') }}
              </div>
            </b-form-invalid-feedback>
          </b-form-group>
          <b-button
            variant="white"
            type="submit"
            class="w-100 font-weight-bold"
            size="lg"
          >
            {{ $t('登入') }}
          </b-button>

          <template v-if="!$device.isMobile">
            <hr class="divider my-4" />
            <p class="text-gray text-center font-weight-bold">
              {{ $t('還沒有帳號嗎？') }}
            </p>
            <b-button
              variant="white"
              size="lg"
              class="w-100 font-weight-bold"
              @click.stop="onClickSignUp"
            >
              {{ $t('免費註冊') }}
            </b-button>
          </template>
        </b-form>
      </div>
    </div>
    <CopyrightFooter mode="primary" />
  </div>
</template>

<script>
import {required, minLength} from 'vuelidate/lib/validators';
import {loginMethods} from '@/constant/env.js';
import CopyrightFooter from '@/components/Base/CopyrightFooter.vue';
import LoginHeader from '@/components/Base/LoginHeader.vue';
import GoogleLoginButton from '@/components/Login/GoogleLoginButton.vue';
import AppleLoginButton from '@/components/Login/AppleLoginButton.vue';
import LineLoginButton from '@/components/Login/LineLoginButton.vue';
import GoogleIcon from '@/assets/img/login/Google.png';
import AppleIcon from '@/assets/img/login/Apple.png';
import LineIcon from '@/assets/img/login/Line.png';
import {getLocalData, saveLocalData} from '@/lib/base/localData';

export default {
  components: {
    LoginHeader,
    CopyrightFooter,
    GoogleLoginButton,
    AppleLoginButton,
    LineLoginButton,
  },
  data() {
    return {
      formData: {
        username: '',
        password: '',
        isUsernameValid: null,
        isPasswordValid: null,
      },
      backendError: '',
      isRegionEditing: false,
      loginMethods,
      GoogleIcon,
      AppleIcon,
      LineIcon,
    };
  },
  validations: {
    formData: {
      username: {
        required,
      },
      password: {required, minLength: minLength(8)},
    },
  },
  computed: {
    isLoading() {
      return this.$store.state.env.isLoading;
    },
  },
  mounted() {
    const loginAccount = getLocalData('login-account');
    if (loginAccount) this.formData.username = loginAccount;
  },
  methods: {
    async requestLogin() {
      if (this.isLoading) return;
      this.$v.$touch();

      if (this.$v.$invalid) {
        this.formData.isUsernameValid = !this.$v.formData.username.$invalid;
        this.formData.isPasswordValid = !this.$v.formData.password.$invalid;
        return;
      }

      await this.loginUser();
    },
    async loginUser() {
      this.$store.commit('env/setIsLoading', true);
      try {
        await this.$store.dispatch('user/login', {
          username: this.formData.username,
          password: this.formData.password,
        });
        await this.$store.dispatch('user/loginWithJwt');
        saveLocalData('login-account', this.formData.username);
        this.$JPush.updateTags();
        this.$router.push('/');
      } catch (error) {
        this.backendError =
          error?.message ===
          'The string supplied did not seem to be a phone number'
            ? 'wrong format'
            : error?.response?.data.message;
        if (this.backendError === 'Wrong password!') {
          this.formData.isPasswordValid = false;
        } else {
          this.formData.isUsernameValid = false;
        }
      } finally {
        this.$store.commit('env/setIsLoading', false);
      }
    },
    usernameInputHandler() {
      this.backendError = '';
      this.formData.isUsernameValid = null;
    },
    passwordInputHandler() {
      this.backendError = '';
      this.formData.isPasswordValid = null;
    },
    onClickSignUp() {
      this.$router.push({name: 'signup'});
    },
  },
};
</script>

<style lang="scss" scoped>
.login-wrapper {
  overflow: overlay;
  height: calc(
    100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom) - 56px - 48px
  );
  @media screen and (min-width: 768px) {
    height: calc(
      100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom) - 68px -
        48px
    );
  }
}
.login-container {
  width: 100%;
  height: 100%;
  padding-top: 24px;
  padding-bottom: 24px;

  @media screen and (min-width: 768px) {
    height: min-content;
    min-height: -webkit-fill-available;
    max-width: 370px;
  }
  h3 {
    font-size: 28px;
    line-height: 44px;
  }
  .oauth-login-btn {
    border: 1px solid $primary;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: white;
    border-radius: $rounded-sm;
    padding: 8px 0;
    img {
      width: 32px;
    }
  }

  .sub-title {
    line-height: 28px;
    background: rgba($primary, $alpha: 0.1);
    border-radius: 14px;
    color: $font-grayscale-1;
  }
  /deep/ label {
    color: $font-grayscale-1;
    line-height: 30px;
    margin-bottom: 2px;
    font-weight: bold;
    font-size: 18px;
  }
  .form-control {
    padding: 9px 15px;
    line-height: 28px;
    height: auto;
  }
  button {
    border-color: $primary;
  }
}
</style>
